import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./AngularTechStacks.scss"
import * as styles from "./AngularTechStacks.module.scss"

const data = [
  {
    title: "Frontend",
    tech: [
      {
        name: "Vue Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/vue_js_89291f021d.svg",
      },
      {
        name: "React Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_js_05724a4f47.svg",
      },
      {
        name: "Angular",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/angular_js_54dbd12f95.svg",
      },
      {
        name: "Next Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/next_js_97a6da81e4.svg",
      },
      {
        name: "Nuxt Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nuxt_js_f8c19a0252.svg",
      },
      {
        name: "Bootstrap",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/bootstrap_55cb92ecc5.svg",
      },
      {
        name: "Tailwind CSS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/tailwind_css_e287cae1c3.svg",
      },
      {
        name: "Materialize CSS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/materialize_css_d99ee09d78.svg",
      },
      {
        name: "React Native",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_native_f257db3858.svg",
      },
      {
        name: "CSS3",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/css_3bfb7febb7.svg",
      },
      {
        name: "Blade",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/blade_60bd71f278.svg",
      },
      {
        name: "SASS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sass_25084a90ef.svg",
      },
      {
        name: "JavaScript",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/javascript_756df51477.svg",
      },
      {
        name: "HTML5",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/html_02b9030f7e.svg",
      },
    ],
  },
  {
    title: "Databases & ORM",
    tech: [
      {
        name: "MongoDB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mongodb_7fe6725ac5.svg",
      },
      {
        name: "PostgreSQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgresql_132ebb1bd7.svg",
      },
      {
        name: "MySQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mysql_4f668011aa.svg",
      },
      {
        name: "SQLite",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sqlite_fc572b0834.svg",
      },
      {
        name: "SQL Server",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sql_server_c5b639c4db.svg",
      },
      {
        name: "Redis",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/redis_25e9f7ecaa.svg",
      },
      {
        name: "DynamoDB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dynamodb_5eb959b40e.svg",
      },
      {
        name: "Firebase",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/firebase_7e579a38cb.svg",
      },
      {
        name: "Elasticsearch",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/elastic_search_1c2e6ec744.svg",
      },
      {
        name: "Eloquent",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/eloquent_8194a175cb.svg",
      },
    ],
  },
  {
    title: "Tools & Packages",
    tech: [
      {
        name: "Sanctum",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sanctum_54fdfb3fb0.svg",
      },
      {
        name: "Passport",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/passport_0e6b3766f5.svg",
      },
      {
        name: "Dusk",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dusk_7d75799244.svg",
      },
      {
        name: "Socialite",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/socialite_5be830b3cc.svg",
      },
      {
        name: "Horizon",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/horizon_15d1b52c18.svg",
      },
      {
        name: "Spatie",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/spatie_7246ed75c6.svg",
      },
    ],
  },
  {
    title: "Backend",
    tech: [
      {
        name: "PHP",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/php_a169d56099.svg",
      },
      {
        name: "Node.js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_eb7b1b50ce.svg",
      },
      {
        name: "Python",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/python_194d0766e0.svg",
      },
      {
        name: "Ruby",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ruby_8727d8f201.svg",
      },
      {
        name: "Java",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_98384bbab5.svg",
      },
      {
        name: "C#",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/c_sharp_02d455b66e.svg",
      },
      {
        name: ".NET",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dot_net_core_9401d82a5b.svg",
      },
      {
        name: "Golang",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/golang_0a8f4b3771.svg",
      },
      {
        name: "Elixir",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/elixir_82ba1db70c.svg",
      },
      {
        name: "Rust",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/rust_c587e1fbf7.svg",
      },
      {
        name: "Swift",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/swift_336b39c600.svg",
      },
      {
        name: "Kotlin",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kotlin_6ebefdccc8.svg",
      },
      {
        name: "Scala",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/scala_dd5fa8959e.svg",
      },
      {
        name: "Mix",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mix_028fed8418.svg",
      },
      {
        name: "Voyager",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/voyager_c9e1777605.svg",
      },
      {
        name: "Telescope",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/telescope_19c06b68a7.svg",
      },
    ],
  },
  {
    title: "Testing",
    tech: [
      {
        name: "PHPUnit",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/php_unit_a0e268510f.svg",
      },
      {
        name: "Postman",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postman_6dbfa6059f.svg",
      },
      {
        name: "Jmeter",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jmeter_4c76f78edc.svg",
      },
      {
        name: "BrowserStack",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/browser_stack_186ed5a8f0.svg",
      },
      {
        name: "Bugzilla",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/bugzilla_70fd44a125.svg",
      },
      {
        name: "SoapUI",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/soapui_7a84f8f709.svg",
      },
      {
        name: "Jasmine",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jasmine_79b40c1d27.svg",
      },
      {
        name: "Karma",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/karma_8f66b89d6a.svg",
      },
      {
        name: "Testbed",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/testbed_55be173871.svg",
      },
      {
        name: "Protractor",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/protractor_4bc8eb9d88.svg",
      },
    ],
  },
]

const AngularTechStacks = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="laravelTech">
      <div className={styles.TechStacksWebHireAngular}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContentAngular}>
              <div>
                <Nav variant="pills" className={`laravelTech`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlockAngularWeb">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                    style={{
                                      height: "60px",
                                      width: "60px",
                                    }}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default AngularTechStacks
