// extracted by mini-css-extract-plugin
export var AngularIndusTechHireWeb = "AngularTechStacksMobile-module--AngularIndusTechHireWeb--499fb";
export var cardBg = "AngularTechStacksMobile-module--cardBg--7147e";
export var cards = "AngularTechStacksMobile-module--cards--73b68";
export var cardsContent = "AngularTechStacksMobile-module--cardsContent--2cf5a";
export var description = "AngularTechStacksMobile-module--description--770e3";
export var heading = "AngularTechStacksMobile-module--heading--b225c";
export var iconssCard = "AngularTechStacksMobile-module--iconssCard--e5b83";
export var nav = "AngularTechStacksMobile-module--nav--87f78";
export var navItem = "AngularTechStacksMobile-module--nav-item--aa494";
export var navbarBlock = "AngularTechStacksMobile-module--navbarBlock--77ac6";
export var tabData = "AngularTechStacksMobile-module--tabData--6d92c";
export var tech = "AngularTechStacksMobile-module--tech--f53cf";
export var techIcon = "AngularTechStacksMobile-module--techIcon--e38b5";
export var techImg = "AngularTechStacksMobile-module--techImg--1fc1a";
export var technologyIcon = "AngularTechStacksMobile-module--technologyIcon--5809e";