import { graphql, Script } from "gatsby"
import React, { useState } from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/hire-pages-compoents/Banner"
import Statics from "../components/hire-typescript/Statics"
import Empower from "../components/hire-pages-compoents/WhyShould"
import WhyHireAngular from "../components/hire-pages-compoents/WhyHireAngular"
import Maximize from "../components/hire-pages-compoents/Maximize"
import HowHireAngular from "../components/hire-python-developers/HowHireAngular"
import HireDevelopers from "../components/hire-pages-compoents/HireDevelopers"
import WhyShould from "../components/hire-pages-compoents/WhyShould"
import AngularTechStacks from "../components/hire-laravel-developers/AngularTechStacks"
import AngularTechStacksMobile from "../components/hire-laravel-developers/AngularTechStacksMobile"
import AngularIndustries from "../components/hire-pages-compoents/AngularIndustries"
import QuickReads from "../components/hire-laravel-developers/QuickReads"
import Solutions from "../components/hire-pages-compoents/Solutions"
import SolutionsMobile from "../components/hire-pages-compoents/SolutionsMobile"
import Success from "../components/hire-pages-compoents/Success"
import Remote from "../components//hire-pages-compoents/Remote"
import Combination from "../components/hire-pages-compoents/FullStackCombination"
import Finest from "../components/web-application/Opportunity"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const Laravel = ({ data }) => {
  console.log(data)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }
  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const heroSection = data?.strapiPage?.sections[0]
  const whyhire = data?.strapiPage?.sections[1]
  const whyShould = data?.strapiPage?.sections[2]
  const maximizeAngular = data?.strapiPage?.sections[3]
  const developers = data?.strapiPage.sections[4]
  const how = data?.strapiPage?.sections[5]
  const rubust = data?.strapiPage?.sections[6]
  const remote = data?.strapiPage?.sections[7]
  const best = data?.strapiPage?.sections[8]
  const TechStacksAngular = data?.strapiPage?.sections[9]
  const cta = data?.strapiPage?.sections[10]
  const industriesAngular = data?.strapiPage?.sections[11]
  const solutions = data?.strapiPage?.sections[12]
  const awesome = data?.strapiPage?.sections[13]
  const quick = data?.strapiPage?.sections[14]
  const faqs = data?.strapiPage?.sections[15]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  const devSDetails = [
    {
      name: "John Matthews",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/john_dev_a9bfea9555.png",
      experience: "8+ years of experience",
      desc: "Working with enterprises, John excels at Laravel features such as routing, security, ORM, templating and data migration, MVC architecture, team management, and PHP Unit testing.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/5_Stars_f7c42777ee.svg",
      reviews: "<b>5.0</b> Reviews",
      skills: [
        "Laravel",
        "PHP",
        "Vue.js",
        "JavaScript",
        "Blade",
        "SASS",
        "Eloquent",
        "Firebase",
        "Python",
        "Postgresql",
        "MySql",
        "Ruby",
        "Java",
        "Java",
        "Sanctum",
        "Passport",
        "Dusk",
        "PHPUnit",
        "RESTful API",
        "Git",
        "Redux",
        "Tailwind CSS",
        "Git Version control",
        "DynamoDB",
      ],
    },
    {
      name: "Sarah M.",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/sarah_dev_e84cdf3161.png",
      experience: "5+ years of experience",
      desc: "Sarah, experienced with startups & mid-level businesses, is pro in MVC architecture & seamless integration of frontend technologies like React Js & JavaScript.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/4_5_Stars_bcd03e6880.svg",
      reviews: "<b>4.8</b> Stars Reviews",
      skills: [
        "Laravel",
        " PHP",
        "Java",
        "JavaScript",
        "SASS",
        "Eloquent",
        "Firebase",
        "Postgresql",
        "Ruby",
        "Elixir",
        "Sanctum",
        "Passport",
        "Dusk",
        "RESTful API",
        "Git",
        "Tailwind CSS",
        "JavaScript",
        "HTML5",
        "",
      ],
    },
    {
      name: "Ryan Jonathan",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/ryan_dev_e34e97e704.png",
      experience: "4+ Years Of Experience",
      desc: "Ryan is great at team collaboration & meeting deadlines. He has a basic understanding of frontend frameworks,Git Version control, and familiarity with MVC.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_5_c3048ffdfe.svg",
      reviews: "<b>4.6</b> Stars Reviews",
      skills: [
        "Laravel",
        "PHP",
        "JavaScript",
        "HTML5",
        "CSS",
        "React",
        "Dusk",
        "RESTful API",
        "Git",
      ],
    },
  ]

  return (
    <MainLayout schemas={bodySchema}>
      <Banner
        strapiData={heroSection}
        breadCrumb={data?.strapiPage?.buttons}
        pageName="Hire Angular Developers"
        laravel={true}
      />
      <Statics />
      <WhyHireAngular strapiData={whyhire} whyHire={true} />
      <WhyShould strapiData={whyShould} />
      <Maximize strapiData={maximizeAngular} hireLaravel={true} />
      <HireDevelopers strapiData={developers} devSDetails={devSDetails} />
      <HowHireAngular strapiData={how} />
      <Empower strapiData={rubust} Vueidentify={true} />
      <Remote strapiData={remote} />
      <Combination strapiData={best} />
      {!isMobile && !isTablet ? (
        <AngularTechStacks strapiData={TechStacksAngular} />
      ) : (
        <AngularTechStacksMobile strapiData={TechStacksAngular} />
      )}
      <Finest strapiData={cta} vsJsBanner={true} />
      <AngularIndustries strapiData={industriesAngular} />
      {!isMobile && !isTablet ? (
        <Solutions strapiData={solutions} />
      ) : (
        <SolutionsMobile strapiData={solutions} />
      )}
      <Success strapiData={awesome} />
      <QuickReads strapiData={quick} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query laravel {
    strapiPage(slug: { eq: "hire-laravel-developers" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Laravel
