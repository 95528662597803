// extracted by mini-css-extract-plugin
export var TechStacksWebHireAngular = "AngularTechStacks-module--TechStacksWebHireAngular--84728";
export var cardBg = "AngularTechStacks-module--cardBg--ab4b4";
export var cards = "AngularTechStacks-module--cards--cd9d1";
export var cardsContentAngular = "AngularTechStacks-module--cardsContentAngular--ae980";
export var description = "AngularTechStacks-module--description--1d76d";
export var heading = "AngularTechStacks-module--heading--05295";
export var iconssCard = "AngularTechStacks-module--iconssCard--55a7e";
export var nav = "AngularTechStacks-module--nav--2a130";
export var navItem = "AngularTechStacks-module--nav-item--9e703";
export var navbarBlock = "AngularTechStacks-module--navbarBlock--7984e";
export var tabData = "AngularTechStacks-module--tabData--0915c";
export var tech = "AngularTechStacks-module--tech--3021e";
export var techIcon = "AngularTechStacks-module--techIcon--65c2b";
export var techImg = "AngularTechStacks-module--techImg--6d5a3";
export var technologyIcon = "AngularTechStacks-module--technologyIcon--b6832";